<template>
  <div>
    <IssueTimeBar
      :alert-url="alertCountUrl"
      :event-url="eventCountUrl"
      :timeRange="timeRange"
      :height="condensedBar ? 5 : 15"
      :xAxisShow="!condensedBar"
      :shortTimeRange="swiperTimeRange || filters.shortTimeRange"
      @time-buckets="(buckets) => { this.timeBuckets = buckets; }"
    ></IssueTimeBar>
    <div>
      <AlertSwiper
        v-if="hasData"
        :groupAlerts="groupAlerts"
        @alert-click="updateTroubleshooting"
      ></AlertSwiper>
      <SpinnerCmpt v-else-if="!loaded"></SpinnerCmpt>
    </div>
    <IssueTroubleshooting
      v-if="focusedAlert"
      :alert="focusedAlert"
      @close="closeTroubleshooting"
    ></IssueTroubleshooting>
  </div>
</template>

<script>
import moment from 'moment';

import AlertSwiper from '@/xvisor/components/issue/AlertSwiper.vue';
import IssueTimeBar from '@/xvisor/components/issue/IssueTimeBar.vue';
import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import IssueTroubleshooting from '@/xvisor/components/issue/IssueTroubleshooting.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import alertSeverities from '@/xvisor/constants/alertSeverities';
import dateCeil from '@/xvisor/utilities/dateCeil';

export default {
  props: {
    alertCountUrl: {
      type: String,
    },
    eventCountUrl: {
      type: String,
    },
    alertsUrl: {
      Type: String,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    isRenderedFromOverview: {
      type: Boolean,
      default: false,
    },
    isInfraPage: {
      type: Boolean,
      default: false,
    },
    // TODO: Remove this flag when all issue time bars have short height.
    condensedBar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AlertSwiper,
    IssueTimeBar,
    IssueTroubleshooting,
    SpinnerCmpt,
  },
  data() {
    const now = dateCeil(new Date());
    const shortTimeRange = { start: moment(now).subtract(5, 'minutes').toDate(), end: now };
    return {
      focusedAlert: undefined,
      timeBuckets: [],
      alertsData: [],
      swiperTimeRange: undefined,
      filters: {
        layer: undefined,
        appUserId: undefined,
        severity: this.isRenderedFromOverview ? alertSeverities.warning : undefined,
        shortTimeRange: this.isRenderedFromOverview ? this.timeRange : shortTimeRange,
        alertsExist: true,
      },
      loaded: true,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.alertsData && this.alertsData.length > 0;
    },
    httpParams() {
      return {
        params: this.isInfraPage
          ? {
            start: this.filters.shortTimeRange.start.toISOString(),
            end: this.filters.shortTimeRange.end.toISOString(),
          }
          : {
            start: this.filters.shortTimeRange.start.toISOString(),
            end: this.filters.shortTimeRange.end.toISOString(),
            threshold: this.filters.severity,
            userId: this.filters.appUserId,
            zoneId: this.filters.zoneId,
            layer: this.filters.layer,
          },
      };
    },
    groupAlerts() {
      return this
        .timeBuckets
        .map((bucket) => ({
          start: bucket.startTime,
          end: bucket.endTime,
          alerts: this.alertsData.filter((alert) => {
            const alertTime = new Date(alert.time);
            return alertTime >= bucket.startTime && alertTime < bucket.endTime;
          }),
        }))
        .filter((bucket) => bucket.alerts.length > 0);
    },
  },
  mounted() {
    IssueTimeBarEventBus.$on('alert-page-change', () => { this.focusedAlert = undefined; });
    IssueTimeBarEventBus.$on('update-filters', this.updateSwiperFilters);
    IssueTimeBarEventBus.$on('swiper-slide-change', (swiperTimeRange) => {
      this.focusedAlert = undefined;
      this.swiperTimeRange = swiperTimeRange;
      this.$emit('update-short-time-range', swiperTimeRange);
    });
    IssueTimeBarEventBus.$on('app-alert-distribution-panel', this.appAlertDistribution);
    IssueTimeBarEventBus.$on('app-alert-zone-distribution-panel', this.appAlertDistribution);
    this.httpGetAlerts();
  },
  watch: {
    timeRange() {
      this.focusedAlert = undefined;
      this.httpGetAlerts();
    },
    filters() {
      this.httpGetAlerts();
    },
    timeBuckets() {
      this.httpGetAlerts();
    },
  },
  methods: {
    updateSwiperFilters(filters) {
      this.focusedAlert = undefined;
      this.$emit('update-short-time-range', this.swiperTimeRange || filters.shortTimeRange);
      this.filters = filters;
    },
    updateTroubleshooting(alert) {
      this.focusedAlert = alert;
      this.$emit('update-focused-alert', alert);
    },
    closeTroubleshooting() {
      this.focusedAlert = undefined;
    },
    appAlertDistribution(filters) {
      this.focusedAlert = undefined;
      this.filters = filters;
      this.filters.shortTimeRange = this.timeRange;
    },
    httpGetAlerts() {
      this.alertsData = [];
      this.swiperTimeRange = undefined;
      if (this.filters.alertsExist && this.filters.shortTimeRange && this.alertsUrl) {
        this.loaded = false;
        this.$http
          .get(this.alertsUrl, this.httpParams)
          .then((response) => {
            this.alertsData = response.data;
            if (this.groupAlerts.length > 0) {
              this.swiperTimeRange = { start: this.groupAlerts[0].start, end: this.groupAlerts[0].end };
              this.$emit('update-short-time-range', this.swiperTimeRange);
            }
          })
          .finally(() => { this.loaded = true; });
      }
    },
  },
};
</script>
