<template>
    <e-chart
      v-if="hasData"
      :options="chartOptions"
      autoresize
    ></e-chart>
    <div v-else-if="loaded" :class="noDataAvailableMargin">{{ $t("No Data Available") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts/map/js/world'; // Ensure the world map is imported.

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    deviceDataUrl: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      devices: [],
      chart: null,
      loaded: false,
      centerUSCoord: [-95.7129, 37.0902],
      centerUSZoomSize: 6,
      defaultCenterCoord: null,
      defaultZoomSize: 1.2,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.devices.length > 0;
    },
    chartOptions() {
      const coordinates = this.devices.map((device) => ({
        deviceCount: device.deviceCount,
        value: device.coord,
      }));
      return {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            const device = this.devices.find((d) => d.coord[0] === params.value[0] && d.coord[1] === params.value[1]);
            if (device) {
              return `${device.deviceCount} Devices<br>City: ${device.city}<br>Country: ${device.country}`;
            }
            return '';
          },
        },
        geo: {
          map: 'world',
          roam: true,
          center: this.devices.every((device) => device.country === 'United States')
            ? this.centerUSCoord : this.defaultCenterCoord,
          zoom: this.devices.every((device) => device.country === 'United States')
            ? this.centerUSZoomSize : this.defaultZoomSize,
          label: {
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: '#9b989c',
              borderColor: '#111',
            },
            emphasis: {
              areaColor: '#9b989c',
            },
          },
        },
        series: [
          {
            name: 'Device Locations',
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
              show: false,
            },
            itemStyle: {
              color: '#fc810d',
            },
            symbolSize: (val) => {
              const device = this.devices.find((d) => d.coord[0] === val[0] && d.coord[1] === val[1]);
              if (device) {
                const size = Math.sqrt(device.deviceCount) * 4;
                return Math.min(Math.max(size, 4), 12);
              }
              return 4;
            },
            data: coordinates,
          },
        ],
      };
    },
  },
  watch: {
    timeRange() {
      this.loadDevices();
    },
  },
  mounted() {
    this.loadDevices();
  },
  methods: {
    loadDevices() {
      this.loaded = false;
      this.$http
        .get('/xtends/deviceslocation.json', {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => {
          this.devices = response.data;
          if (this.chart) {
            this.chart.setOption(this.chartOptions);
          }
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    initChart() {
      this.chart = echarts.init(this.$refs.worldMapChart);
      this.chart.setOption(this.chartOptions);
      window.addEventListener('resize', this.resizeChart);
    },
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};

</script>
